.contact-container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .contact-left {
    position: relative;
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .contact-topLeft {
      position: absolute;
      top: 1em;
      left: 14em;
    }

    .contact-topRight {
      position: absolute;
      top: 6em;
      right: 6em;
    }

    .contact-centerRight {
      position: absolute;
      top: 42vh;
      right: -3em;
    }

    .contact-bottomLeft {
      position: absolute;
      bottom: 3em;
      left: 14em;
    }

    .contact-bottomRight {
      position: absolute;
      bottom: 0;
      right: -4em;
    }
  }

  .contact-right {
    width: 50%;
    height: 100vh;
  }
}

@media only screen and (max-width: 810px) {
  .contact-container {
    .contact-left {
      display: none;
    }

    .contact-right {
      width: 100%;
      height: 100vh;
    }
  }
}
