.exp-container {
  padding-left: 4em;
  padding-right: 4em;
  padding-top: 2em;
  padding-bottom: 2em;
  position: relative;

  .mailer {
    position: absolute;
    top: 2em;
    right: 2.5em;
    z-index: 2;
  }

  .grid-container {
    width: 100%;
    height: 100%;
    // display: flex;
    display: grid;
    grid-template-columns: 44% 12% 44%;
    z-index: 5;
    margin-top: 5em; // TODO: Change

    .line-middle {
      margin: 6.5em;
    }

    .grid-item-left {
      display: grid;

      .card-left {
        margin-top: 20em;
      }
    }

    .grid-item-right {
      display: grid;

      .card-right {
        margin-bottom: 20em;
      }
    }
  }
}

@media only screen and (max-width: 1320px) {
  .exp-container {
    padding-top: 5em;

    .mailer {
      display: none;
    }

    .heading {
      margin-top: 2em;
    }

    .grid-container {
      margin-top: 5em;
      grid-template-columns: 100%;

      .line-middle {
        display: none;
      }
      .grid-item-left {
        grid-template-rows: none;
        display: flex;
        flex-direction: column;

        .card-left {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      .grid-item-right {
        grid-template-rows: none;
        display: flex;
        flex-direction: column;
        .card-right {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
