@import './common';

@import './loader';

@import './components';

@import './home';

@import './skills';

@import './contact';

@import './work';

@import './experience';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $Montserrat;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bgColor;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: rgb(61, 61, 61);
}

::-webkit-scrollbar-thumb {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: wheat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nodemon {
  margin-left: 10em;
  width: calc(100vw - 10em);
  height: 100vh;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.cursor {
  position: fixed;
  background-color: white;
  border-radius: 50%;
  z-index: 200;
  transition: 0.1s linear;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
}

@media (max-width: 719px) {
  .nodemon {
    margin-left: 0;
    width: 100vw;
  }

  .cursor {
    visibility: hidden;
  }
}

@media only screen and (min-width: 1600px) {
  body {
    font-size: 77%;
  }
}

@media only screen and (min-width: 1900px) {
  body {
    font-size: 90%;
  }
}

@media only screen and (min-width: 2200px) {
  body {
    font-size: 100%;
  }
}
