$size: 120px;
// $color1: #3a60db;
$color1: #1b1b1b;
$color2: #fff;

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($prop...) {
  animation: $prop;
}

@mixin transform($prop...) {
  transform: $prop;
}

@mixin transform-origin($prop...) {
  transform-origin: $prop;
}

.load {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  &::before,
  &::after,
  .dot,
  .outline {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    @include transform(translate(-50%, -50%));
  }

  .dot {
    width: #{$size - $size / 100 * 20};
    height: #{$size - $size / 100 * 20};
    background: $color2;
    z-index: 2500;
    @include animation(in 4s linear infinite);
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    background: $color1;
    @include animation(out1 4s linear infinite);
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    background: $color2;
    @include animation(out2 4s linear infinite);
  }

  .outline {
    width: $size;
    height: $size;
    z-index: 2500;

    span {
      width: $size / 2 + 8;
      height: $size / 2 + 8;
      overflow: hidden;
      position: absolute;
      bottom: 50%;
      right: 50%;
      @include transform-origin(100% 100%);
      @include transform(rotate(45deg) skewX(80deg));
      @include animation(outline 4s linear infinite);

      &::before {
        content: '';
        display: block;
        border: solid 5px #fff;
        width: 200%;
        height: 200%;
        border-radius: 50%;
        @include transform(skewX(-80deg));
        @include animation(outlineBefore 4s linear infinite);
      }
    }
  }
}

@media only screen and (max-width: 719px) {
  .load {
    transform: scale(2);
  }
}

@include keyframes(outline) {
  0% {
    @include transform(rotate(0deg) skewX(80deg));
  }
  25% {
    @include transform(rotate(500deg) skewX(15deg));
  }
  50% {
    @include transform(rotate(1000deg) skewX(40deg));
  }
  75% {
    @include transform(rotate(1500deg) skewX(60deg));
  }
  100% {
    @include transform(rotate(2160deg) skewX(80deg));
  }
}

@include keyframes(outlineBefore) {
  0% {
    border: solid 5px #fff;
    @include transform(skewX(-80deg));
  }
  25% {
    border: solid 5px #fff;
    @include transform(skewX(-15deg));
  }
  49% {
    border: solid 5px #fff;
  }
  50% {
    border: solid 5px #1b1b1b;
    @include transform(skewX(-40deg));
  }
  75% {
    border: solid 5px #1b1b1b;

    @include transform(skewX(-60deg));
  }
  100% {
    border: solid 5px #1b1b1b;
    @include transform(skewX(-80deg));
  }
}

@include keyframes(in) {
  0% {
    width: #{$size + $size / 100 * 20};
    height: #{$size + $size / 100 * 20};
    background: $color2;
  }
  40% {
    width: 0;
    height: 0;
    background: $color2;
  }
  41% {
    width: 0;
    height: 0;
    background: $color1;
  }
  50% {
    width: #{$size + $size / 100 * 20};
    height: #{$size + $size / 100 * 20};
    background: $color1;
  }
  90% {
    width: 0;
    height: 0;
    background: $color1;
  }
  91% {
    width: 0;
    height: 0;
    background: $color2;
  }
  100% {
    width: #{$size + $size / 100 * 20};
    height: #{$size + $size / 100 * 20};
    background: $color2;
  }
}

@include keyframes(out1) {
  0% {
    width: 0;
    height: 0;
  }
  30% {
    width: 120vw;
    height: 120vw;
  }
  100% {
    width: 120vw;
    height: 120vw;
  }
}

@include keyframes(out2) {
  0% {
    width: 0;
    height: 0;
  }
  30% {
    width: 0;
    height: 0;
  }
  60% {
    width: 120vw;
    height: 120vw;
  }
  100% {
    width: 120vw;
    height: 120vw;
  }
}
