.btn-global {
  border: none;
  outline: none;
  color: black;
  height: 100%;
  width: 100%;
  border-radius: 2.5em;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 700;
  cursor: pointer;
}

.btn-global:hover {
  background-color: #1b1b1b !important;
  color: wheat;
}

.form-container {
  width: 100%;
  height: 100%;
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.07), -2px 2px 4px rgba(0, 0, 0, 0.07),
    -4px 4px 8px rgba(0, 0, 0, 0.07), -8px 8px 16px rgba(0, 0, 0, 0.07);
  background-color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;

  form {
    display: flex;
    padding: 0 7em;
    flex-direction: column;
    width: 100%;

    .form-label {
      color: white;
      font-weight: 500;
      max-width: 100%;
      font-size: 2em;
      margin-bottom: 0.5em;
    }

    .form-input {
      margin-bottom: 1.5em;
      max-width: 100%;
      background-color: #232423;
      border: none;
      padding: 1em 0.8em;
      outline: none;
      font-size: 1.7em;
      color: #aaa;
    }

    .textarea {
      height: 8em;
      max-width: 100%;
      margin-bottom: 2.2em;
      font-size: 2.5em;
    }

    .btn-global {
      width: 12em;
      align-self: center;
      height: 3.5em;
    }
  }
}

.rating {
  span {
    margin: 0.1em;
  }
}

.credits {
  color: white;
  font-size: 0.75em;
  letter-spacing: 0.1em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  h3 {
    text-transform: uppercase;
    margin-bottom: 0.3em;
  }

  h4 {
    span {
      &:nth-child(1) {
        font-size: 1.1em;
        font-weight: 600;
        color: #4dfff4;
      }
      &:nth-child(2) {
        font-size: 1.1em;
        font-weight: 600;
        color: #faff00;
      }
      &:nth-child(3) {
        font-size: 1.1em;
        font-weight: 700;
        color: #ff3062;
      }
    }
  }
}

.mail-info {
  display: flex;
  align-items: center;
  cursor: pointer;

  .circle {
    height: 5em;
    width: 5em;
    border: 0.1em solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    svg {
      display: block;
      width: 45%;
      height: auto;
      color: white;
      transition: all 0.3s ease;
    }
  }

  h3 {
    margin-left: 1.8em;
    color: white;
    font-size: 1.4em;
  }

  &:hover {
    .circle {
      background-color: white;

      svg {
        color: black;
      }
    }
  }
}

.card {
  height: auto;
  width: 100%;
  position: relative;
  background-color: #2c2d2d;
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.07), -2px 2px 4px rgba(0, 0, 0, 0.07),
    -4px 4px 8px rgba(0, 0, 0, 0.07), -8px 8px 16px rgba(0, 0, 0, 0.07);
  padding: 1.2em 2.8em;

  h3 {
    font-size: 2.7em;
    color: white;
    font-weight: 700;
  }

  h4 {
    font-size: 1.8em;
    color: #a1a1a1;
    font-weight: 700;
    margin: 0.35em 0;
  }

  hr {
    opacity: 0.6;
    margin: 1.2em 0;
  }

  h5 {
    font-size: 1.5em;
    color: #a1a1a1;
    font-weight: 600;
  }

  .line {
    position: absolute;
    background-color: #fff;
    right: 0;
    top: 0;
    width: 0.5em;
    height: 100%;

    &.left {
      left: 0;
    }
  }
}

.nav-item {
  display: flex;
  width: auto;
  padding: 3em;

  h2 {
    color: white;
    font-size: 2.8em;
    font-weight: 300;
    transform: rotateZ(-90deg);
    margin-right: 0.15em;
  }

  .header {
    font-size: 5.5em;
    font-weight: 700;
    color: white;
    cursor: pointer;

    &::after {
      content: '';
      position: relative;
      display: block;
      width: 0;
      height: 0.02em;
      background-color: rgba(255, 255, 255, 0.3);
    }
    &.active {
      color: wheat;
    }
  }
}

//heading
.page-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .head {
    display: flex;
    align-items: center;
    margin-bottom: 1.3em;

    .page-line {
      width: 3.2em;
      height: 0.25em;
      border-radius: 2px;
      background-color: white;
      margin-right: 1.4em;
    }

    h2 {
      color: white;
      font-size: 2.25em;
    }
  }

  h1 {
    font-size: 2.9em;
    color: white;
  }
}

.skill-container {
  width: 100%;
  height: 100%;
  display: flex;
  color: white;
  font-weight: 700;
  justify-content: flex-start;

  .left {
    padding-top: 10em;

    .left-flex {
      display: flex;
      justify-content: space-around;
      margin-bottom: 0.8em;
      font-size: 3.5em;
      position: relative;

      .right-arrow {
        margin-right: 0.8em;
        visibility: hidden;

        &.active {
          visibility: visible;
        }
      }

      .heading {
        cursor: pointer;
        text-align: left;

        &:hover {
          color: wheat;
        }
      }

      .underline {
        position: absolute;
        width: 7em;
        height: 0.05em;
        background-color: white;
        bottom: -0.2em;
        right: 0;
      }
    }
  }

  .middle {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: max-content;

    .skill-circle {
      height: 10em;
      width: 10em;
      align-self: center;
      background: linear-gradient(
        0deg,
        #ee7752 0%,
        #e73c7e 32.81%,
        #23a6d5 65.62%,
        #23d5ab 100%
      );
      border-radius: 50%;
      transform: rotate(-0.25deg);
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: 0.3em;
        border-radius: inherit;
        background: black;
      }

      .skill-circle-inner {
        height: 8em;
        width: 8em;
        background: linear-gradient(
          0deg,
          #ee7752 0%,
          #e73c7e 32.81%,
          #23a6d5 65.62%,
          #23d5ab 100%
        );
        border-radius: 50%;
        transform: rotate(-0.25deg);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          margin: 0.3em;
          border-radius: inherit;
          background: black;
        }
      }
    }

    .skill-line {
      height: 100%;
      width: 0.3em;
      // background-color: white;
      background: linear-gradient(
        180deg,
        #ee7752 0%,
        #e73c7e 32.81%,
        #23a6d5 65.62%,
        #23d5ab 100%
      );
      border-radius: 2em;
    }
  }

  .right {
    padding-top: 12em;

    .right-flex {
      display: flex;
      justify-content: flex-start;
      font-size: 2.2em;
      margin-bottom: 1.2em;

      .logo {
        margin-right: 0.8em;
        min-width: 2em;
      }

      .skill {
        margin-bottom: 0.5em;
      }

      .header {
        .logo {
          font-size: 0.62em;
        }
      }
    }
  }
}

//sidebar
.sidepanel {
  position: relative;

  .sidepanel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99999999;
  }

  .sidepanel-container {
    position: fixed;
    z-index: 101;
    height: 100vh;
    width: 10em;
    top: 0;
    left: 0;
    background-color: #121212;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .sidepanel-inside {
      height: 97vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3s ease;

      .menu-logo > img {
        width: 5.3em;
        border: solid white 0.2em;
        padding: 0.3em 0.7em;
        padding-bottom: 0.9em;
      }

      .logo1 {
        color: white;
        width: 4em;
        height: 6em;
        cursor: pointer;
        transition: all 0.3s ease;
      }

      .logos {
        flex-direction: column;
        justify-content: space-around;
      }

      .menu-icon {
        cursor: pointer;
        margin-top: 9em;
        z-index: 1000;
      }
    }
  }

  .sidepanel-open {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #121212;
    padding-left: 20em;

    .container {
      display: flex;
      height: 100vh;
      width: 0;
      flex-direction: column;
      justify-content: center;
    }

    .copyright {
      position: absolute;
      z-index: 101;
      bottom: 1em;
      right: 1em;
    }
  }
}

// MyLine
.myline {
  // height: auto;
  width: 0.6em;
  position: relative;
  background: linear-gradient(
    180deg,
    #ee7752 0%,
    #e73c7e 32.81%,
    #23a6d5 65.62%,
    #23d5ab 100%
  );
  border-radius: 2em;
  margin-top: 7.5em;

  .circle {
    position: relative;
    right: 2.1em;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    z-index: 2;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.1);

    .active {
      position: absolute;
      top: -1em;
      right: -1.1em;
      z-index: 3;
      background-color: none;
      height: 7em;
      width: 7em;
      border-radius: 50%;
      border: 0.2em solid white;
    }
  }

  .line {
    position: absolute;
    top: 2.5em;
    z-index: -555;
    width: 10em;
    border: 0.2em dashed white;
    border-style: none none dotted;
    color: #fff;

    &.left {
      left: 0;
    }
    &.right {
      right: 2em;
    }
  }
}

@media only screen and (max-width: 719px) {
  .sidepanel {
    position: relative;

    .sidepanel-container {
      position: fixed;
      z-index: 101;
      height: 5em;
      width: 100vw;
      bottom: 0;
      right: 0;
      background-color: #121212;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      // padding: 2em;

      .sidepanel-inside {
        width: 97vw;
        height: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s ease;

        .hide-this {
          display: none;
        }

        .logo1 {
          color: white;
          width: 2em;
          height: 2em;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            color: wheat !important;
          }
        }

        .logos {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-right: 2em;

          .logo2 {
            padding: 0.5em;
          }
        }

        .menu-icon {
          margin-top: 0;
          cursor: pointer;
        }
      }
    }

    .sidepanel-open {
      width: 100vw;
      height: 100vh;
      position: fixed;
      z-index: 100;
      bottom: 0;
      left: 0;
      background-color: #121212;
      padding-left: 6em;
      // overflow-y: scroll;

      .container {
        display: flex;
        height: 100vh;
        flex-direction: column;
        justify-content: center;
      }

      .copyright {
        position: absolute;
        z-index: 101;
        bottom: 1em;
        right: 1em;
      }
    }
  }
  //sidepanel
  .nav-item {
    display: flex;
    padding: 2em;

    h2 {
      color: white;
      font-size: 1em;
      font-weight: 200;
      transform: rotateZ(-90deg);
      margin-right: 0.15em;
    }

    .header {
      font-size: 3.8em;
      font-weight: 700;
      color: white;
      cursor: pointer;
    }

    &:hover {
      color: rgb(255, 0, 106);
    }
  }

  //skill
  .skill-container {
    width: 100%;
    height: 100%;
    display: flex;
    color: white;
    font-weight: 600;
    justify-content: flex-start;
    margin-top: 4em;

    .left {
      padding-top: 0;

      .left-flex {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0.7em;
        font-size: 2.3em;
        position: relative;

        .right-arrow {
          display: none;
        }

        .heading {
          cursor: pointer;
          margin-top: 0;
          &:hover {
            color: wheat;
          }
        }

        .underline {
          position: absolute;
          width: 6em;
          height: 0.05em;
          background-color: white;
          bottom: -0.2em;
          left: 0;
        }
      }
      .right-flex {
        display: flex;
        justify-content: flex-start;
        font-size: 1.6em;
        margin-bottom: 1.3em;
        font-weight: 300;

        .logo {
          margin-right: 0.8em;
        }

        .skill {
          margin-bottom: 0;
          margin-right: 0.5em;
        }
        .header {
          display: flex;
          flex-direction: row;
          align-items: center;

          .logo {
            margin-bottom: 0;
            font-size: 0.62em;
          }
        }
      }
    }

    .middle {
      display: none;
    }
  }
}

@media only screen and (max-width: 719px) {
  //heading
  .page-header {
    .head {
      .page-line {
        width: 4em;
      }
      h2 {
        font-size: 1.9em;
      }
    }
    h1 {
      font-size: 2.2em;
    }
  }
}

@media only screen and (max-width: 719px) {
  .form-container {
    form {
      .form-label {
        font-size: 1.6em;
      }

      .form-input {
        font-size: 1.3em;
      }

      .textarea {
        font-size: 1.7em;
      }

      .btn-global {
        margin-bottom: 5em;
        width: 75%;
        font-size: 1.1em;
      }
    }
  }
}
