.skills-container {
  padding-left: 4em;
  padding-right: 4em;
  padding-top: 2em;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .mailer {
    position: absolute;
    top: 2em;
    right: 2.5em;
    z-index: 10;
  }

  .tree {
    z-index: 10;
  }

  .skill-triangle {
    position: absolute;
    top: 2em !important;
    right: 8em;
  }

  .skill-blob {
    position: absolute;
    bottom: -26em;
    right: -20em;
  }
}

@media only screen and (max-width: 719px) {
  .skills-container {
    padding-top: 5em;

    .mailer {
      display: none;
    }

    .skill-triangle,
    .skill-blob {
      opacity: 0.4 !important;
      z-index: -10;
    }

    .top-heading {
      margin-top: 2em;
    }
  }
}
