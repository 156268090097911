.work-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 10em;

  .work-overlay {
    // background: linear-gradient(
    //   87.26deg,
    //   #000 21.95%,
    //   rgba(126, 126, 126, 0.39) 163.42%
    // );
    background-color: #000;
    opacity: 0.75;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }

  .backgroundImage {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
  }

  .designation-grid {
    position: relative;
    z-index: 30;

    .designation {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 2em;
      padding-right: 2em;
      margin-bottom: 3em;

      .line {
        width: 5em;
        height: 0.2em;
        background-color: white;
        border-radius: 0.2em;
        margin-bottom: 0.2em;
      }

      h1 {
        font-size: 6em;
        color: white;
        margin-bottom: 0.2em;
      }

      p {
        font-weight: 400;
        font-size: 1.4em;
        color: white;
        margin-bottom: 1.5em;
        filter: drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.18))
          drop-shadow(2px 2px 4px rgba(255, 255, 255, 0.18))
          drop-shadow(3px 3px 6px rgba(255, 255, 255, 0.18));
      }

      .btn-cont {
        width: 12em;
        height: 4em;
      }
    }
  }

  .slider-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    .card-container {
      display: flex;
      overflow-x: scroll;
      padding: 1em;

      &::-webkit-scrollbar {
        display: none;
      }

      .card-inner {
        display: table;
        margin-right: 1.4em;
        width: 18em;
        height: 22em;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 1em;
        box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.63);
        cursor: pointer;
      }
    }

    .controller {
      width: 90%;
      height: 3em;
      margin-bottom: 3.5em;
      margin-top: 2em;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .controls {
        display: flex;
        cursor: pointer;

        & > div {
          margin-right: 1em;
        }
      }

      .progressbar {
        background-color: none;
        border: 1px white solid;
        width: 30.4em;
        border-radius: 1em;
        padding: 0.1em;

        & > div {
          background-color: rgb(255, 255, 255);
          height: 0.2em;
          border-radius: 1em;
        }
      }

      .slide {
        font-size: 5em;
        font-weight: 800;
        color: white;
      }
    }
  }
}
@media only screen and (max-width: 1125px) {
  .work-container {
    grid-template-columns: 40% 60%;
    grid-gap: 0;

    .designation-grid {
      height: auto;
    }

    .slider-box {
      .controller {
        width: 100%;

        .progressbar {
          display: none;
        }
      }
    }
  }
}
