.home-container {
  background: linear-gradient(259.25deg, #1a1a1a 6.61%, #000 84%);
  position: relative;
  overflow-y: hidden;

  .mailer {
    position: absolute;
    top: 2em;
    z-index: 30;
    right: 2.5em;
  }

  .home {
    height: 100vh;
    overflow-y: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .blob {
      position: relative;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 60em;
        width: 70em;
        display: none;
      }

      img {
        position: absolute;
        top: 12em;
        left: 4em;
        height: 40em;
        width: 40em;
      }

      .gradient-overlay-1,
      .gradient-overlay-2 {
        position: absolute;
        top: 8.5em;
        left: 0;
        border-radius: 50%;
        height: 50em;
        width: 50em;
        background: linear-gradient(
          250.94deg,
          rgba(0, 0, 0, 0) 30%,
          rgb(0, 0, 0) 85.9%
        );
        z-index: 20;
      }

      .gradient-overlay-2 {
        background: linear-gradient(
          180.94deg,
          rgba(255, 255, 255, 0) 50%,
          rgb(0, 0, 0) 85.9%
        );
        z-index: 21;
      }

      .home-para {
        display: flex;
        flex-direction: column;
        width: 60em;
        align-items: flex-start;
        position: absolute;
        left: 50em;
        top: 12em;
        z-index: 50;

        h3 {
          font-weight: 700;
          font-size: 5.5em;
          margin-bottom: 0.4em;
          text-shadow: rgb(239 237 255) -1px -1px 0, rgb(239 237 255) 1px -1px 0,
            rgb(239 237 255) -1px 1px 0, rgb(239 237 255) 1px 1px 0;
          color: rgba(16, 16, 16, 1);
        }

        h2 {
          font-weight: 700;
          font-size: 5em;
          color: white;
          margin-bottom: 0.4em;
        }

        h5 {
          font-weight: 300;
          font-size: 1.7em;
          width: 95%;
          color: #bababa;
          // line-height: 1.7em;
          margin-bottom: 1.5em;
        }

        .button {
          display: flex;
          width: 30%;
          height: 5em;
        }
      }
    }
  }
  .triangle {
    position: absolute;
    right: 8em;
    top: 7em;
    z-index: 3;
  }
  .circle-svg {
    position: absolute;
    right: 8em;
    bottom: 3em;
    z-index: 3;
  }
}

@media only screen and (max-width: 1180px) {
  .home-container {
    .home {
      overflow-y: auto;
      // padding-left: 19vw; // TODO: Movement

      .blob {
        position: relative;

        svg {
          display: block;
        }

        img {
          display: none;
        }

        .gradient-overlay-1,
        .gradient-overlay-2 {
          display: none;
        }

        .home-para {
          display: flex;
          flex-direction: column;
          width: 60em;
          align-items: flex-start;
          position: absolute;
          left: 10em;
          top: 25em;

          h3 {
            font-weight: 700;
            font-size: 3.5em;
            margin-bottom: 0.4em;
            text-shadow: rgb(239 237 255) -1px -1px 0,
              rgb(239 237 255) 1px -1px 0, rgb(239 237 255) -1px 1px 0,
              rgb(239 237 255) 1px 1px 0;
            color: rgba(16, 16, 16, 1);
          }

          h2 {
            font-weight: 700;
            font-size: 3.5em;
            color: white;
            margin-bottom: 0.4em;
          }

          h5 {
            font-weight: 400;
            font-size: 1.4em;
            width: 95%;
            color: #bababa;
            // line-height: 1.7em;
            margin-bottom: 2.5em;
          }

          .button {
            margin-bottom: 5em;
          }
        }
      }
    }
    .triangle {
      visibility: hidden;
    }
    .circle-svg {
      visibility: hidden;
    }
  }
}

@media only screen and (max-width: 800px) {
  .home-container {
    .mailer {
      top: 3.5em;
    }
    .home {
      overflow-y: hidden;
      align-items: flex-start;

      .blob {
        position: relative;

        svg {
          height: 60em;
          width: 40em;
        }

        img {
          height: 60em;
          width: 40em;
        }

        .home-para {
          padding: 0;
          width: 40em;
          left: 5em;
          top: 30em;

          .button {
            margin-bottom: 5em;
            width: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 719px) {
  .home-container {
    .mailer {
      top: 7.5em;
    }
  }
}

@media only screen and (max-width: 580px) {
  .home-container {
    .home {
      overflow-y: hidden;
      padding-left: 7vw; // TODO: Movement
      align-items: flex-start;

      .blob {
        position: relative;

        svg {
          height: 60em;
          width: 30em;
        }

        img {
          height: 60em;
          width: 30em;
        }

        .home-para {
          padding: 0;
          left: 3em;
          top: 30em;
          width: 30em;

          h3 {
            font-weight: 700;
            font-size: 2.7em;
            margin-bottom: 0.4em;
            text-shadow: rgb(239 237 255) -1px -1px 0,
              rgb(239 237 255) 1px -1px 0, rgb(239 237 255) -1px 1px 0,
              rgb(239 237 255) 1px 1px 0;
            color: rgba(16, 16, 16, 1);
          }

          h2 {
            font-weight: 700;
            font-size: 2em;
            color: white;
            margin-bottom: 0.4em;
          }

          h5 {
            font-weight: 400;
            font-size: 1.2em;
            width: 95%;
            color: #bababa;
            // line-height: 1.7em;
            margin-bottom: 2.5em;
          }

          .button {
            margin-bottom: 5em;
            width: 40%;
            font-size: 0.8em;
          }
        }
      }
    }
  }
}
